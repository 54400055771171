import { Status } from 'types/IndustryReport'

const publicationStatusMapper = (
  status: Status,
  publishedString: string,
  draftString: string,
  archivedString: string
) => {
  switch (status) {
    case 'Published':
      return publishedString
    case 'Draft':
      return draftString
    case 'Archived':
      return archivedString

    default:
      return ''
  }
}

export default publicationStatusMapper

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AppShellRouter, KeyRoute } from 'router'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale'
import IndustryReportList from 'features/IndustryReport/IndustryReportList'
import IndustryReportCreate from 'features/IndustryReport/IndustryReportCreate'
import IndustryReportEdit from 'features/IndustryReport/IndustryReportEdit'
import { Provider } from 'react-redux'
import { store } from 'store'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  let theme = createTheme(themeOptions, defaultLocale)
  theme = responsiveFontSizes(theme)

  return (
    <AppShellDataProvider value={appShellData}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <AppShellRouter currentKey={appShellData.currentKey}>
              <KeyRoute urlKey="prognosportalen-administration">
                <IndustryReportList />
              </KeyRoute>
              <KeyRoute urlKey="ny-publikation">
                <IndustryReportCreate />
              </KeyRoute>
              <KeyRoute urlKey="redigera-publikation">
                <IndustryReportEdit />
              </KeyRoute>
            </AppShellRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </AppShellDataProvider>
  )
}

export default App

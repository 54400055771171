import { Autocomplete, Chip, Stack, TextField } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import useDebounce from 'hooks/useDebounce'
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { type ControllerFieldState } from 'react-hook-form'
import { useLazyGetLocationSuggestionsQuery } from 'services/referenceData'
import { Content } from 'types/Content.type'
import { Region } from 'types/IndustryReport'

interface RegionsAutocompleteProps {
  regions?: Region[]
  onChange: (...event: unknown[]) => void
  fieldState: ControllerFieldState
}

const RegionsAutocomplete = (
  { regions, onChange, fieldState }: RegionsAutocompleteProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    redigeraPublikation: { regionsAutocompleteNoOptionsText },
  } = useEpiContent<Content>()
  const [trigger] = useLazyGetLocationSuggestionsQuery()
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 300)
  const [options, setOptions] = React.useState<Region[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await trigger(debouncedInputValue)
      const options = response.data as Region[]

      setOptions(options)
    }
    if (debouncedInputValue !== '') {
      return void fetchData()
    }

    setOptions([])
  }, [debouncedInputValue, trigger])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(event.target.value)
  }

  return (
    <>
      <Autocomplete
        data-testid="regions-field"
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            value={inputValue}
            label={regionsAutocompleteNoOptionsText}
            onChange={handleInputChange}
            error={fieldState.error?.message ? true : false}
            helperText={fieldState.error?.message}
            required
          />
        )}
        inputValue={inputValue}
        onChange={(event: unknown, newValue: Region | null) => {
          // Sometimes newValue is null on this event so we need to have a null check here.
          if (newValue) {
            onChange([
              ...(regions as Region[]),
              { id: newValue.id, name: newValue.name },
            ])
            setInputValue('')
          }
        }}
        getOptionLabel={(option) => option.name}
        noOptionsText={regionsAutocompleteNoOptionsText}
        getOptionDisabled={(option) =>
          regions?.some((e) => e.id === option.id) ?? false
        }
      />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={'13px'}
        flexWrap="wrap"
        useFlexGap
      >
        {regions?.map((region) => {
          return (
            <Chip
              key={region.id}
              variant="outlined"
              onDelete={() => {
                onChange(regions.filter((val) => val.id !== region.id))
              }}
              label={region.name}
            />
          )
        })}
      </Stack>
    </>
  )
}

export default forwardRef(RegionsAutocomplete)

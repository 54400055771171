import { IndustryReport } from 'types/IndustryReport'
import { parseISO } from 'date-fns'
import { Data } from 'features/IndustryReport/IndustryReportList/Filters/Filter/Filter'
import { OccupationGroup } from 'types/OccupationGroup'

import api from '../api'

const kompetensApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIndustryReports: build.query<
      IndustryReport[],
      {
        searchQuery: string
        regions?: string[]
        statuses?: string[]
        occupationGroups?: string[]
      }
    >({
      query: (arg) => {
        return {
          url: `/v1.0/kompetens/IndustryReportAdmin`,
          params: {
            searchQuery: arg.searchQuery,
            ...(arg.regions &&
              arg.regions.length > 0 && { regions: arg.regions }),
            ...(arg.statuses &&
              arg.statuses.length > 0 && { statuses: arg.statuses }),
            ...(arg.occupationGroups &&
              arg.occupationGroups.length > 0 && {
                occupationGroups: arg.occupationGroups,
              }),
          },
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'IndustryReport' as const,
                id,
              })),
              'IndustryReport',
            ]
          : ['IndustryReport'],
      transformResponse: (response: IndustryReport[]): IndustryReport[] =>
        response.sort(
          (a, b) =>
            parseISO(b.revisions.slice(-1)[0].updatedAt).getTime() -
            parseISO(a.revisions.slice(-1)[0].updatedAt).getTime()
        ),
    }),
    getIndustryReport: build.query<IndustryReport, string>({
      query: (id) => `/v1.0/kompetens/IndustryReportAdmin/${id}`,
      providesTags: (result, error, arg) => [
        { type: 'IndustryReport', id: arg },
      ],
      transformResponse: (response: IndustryReport): IndustryReport => {
        return { ...response, latestRevision: response.revisions.pop() ?? null }
      },
    }),
    addIndustryReport: build.mutation<IndustryReport, File>({
      query: (file) => {
        const formData = new FormData()
        formData.append('File', file, encodeURIComponent(file.name))

        return {
          url: `/v1.0/kompetens/IndustryReportAdmin/upload`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ['IndustryReport'],
    }),
    updateIndustryReport: build.mutation<
      IndustryReport,
      Partial<IndustryReport>
    >({
      query: (formData) => ({
        url: `/v1.0/kompetens/IndustryReportAdmin/${formData.id ?? ''}`,
        body: formData,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'IndustryReport', id },
      ],
    }),
    deleteIndustryReport: build.mutation<IndustryReport, string>({
      query: (id) => {
        return {
          url: `/v1.0/kompetens/IndustryReportAdmin/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'IndustryReport', id }],
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getOccupationGroups: build.query<Data[], void>({
      query: () => `/v1.0/kompetens/IndustryReportAdmin/occupationGroups`,
      transformResponse: (response: OccupationGroup[]): Data[] =>
        response.map((el) => ({
          id: el.id,
          label: el.occupationGroupName,
        })),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'County' as const,
                id,
              })),
              'County',
            ]
          : ['County'],
    }),
  }),
})

export const {
  useGetIndustryReportsQuery,
  useGetIndustryReportQuery,
  useLazyGetIndustryReportQuery,
  useAddIndustryReportMutation,
  useUpdateIndustryReportMutation,
  useDeleteIndustryReportMutation,
  useGetOccupationGroupsQuery,
} = kompetensApi

import { Autocomplete, Chip, Stack, TextField } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import useDebounce from 'hooks/useDebounce'
import React, { useEffect, useState, forwardRef, ForwardedRef } from 'react'
import { type ControllerFieldState } from 'react-hook-form'
import { useLazyGetOccupationGroupSuggestionsQuery } from 'services/referenceData/referenceData'
import { Content } from 'types/Content.type'
import { OccupationField, OccupationGroup } from 'types/OccupationGroup'

interface OccupationGroupsAutocompleteProps {
  occupationgroups?: OccupationGroup[]
  onChange: (...event: unknown[]) => void
  fieldState: ControllerFieldState
}

export interface OccupationGroupExtended extends OccupationGroup {
  parent: OccupationField
}

const OccupationGroupsAutocomplete = (
  { occupationgroups, onChange, fieldState }: OccupationGroupsAutocompleteProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    redigeraPublikation: { occupationGroupsAutocompleteNoOptionsText },
  } = useEpiContent<Content>()
  const [trigger] = useLazyGetOccupationGroupSuggestionsQuery()
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 300)

  const [options, setOptions] = React.useState<OccupationGroupExtended[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await trigger(debouncedInputValue)
      const options = response.data as OccupationGroupExtended[]

      const sortedOptions = [...options]
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        .filter((item): item is OccupationGroupExtended => !!item)
        .sort((a, b) => -b.parent.title.localeCompare(a.parent.title))

      setOptions(sortedOptions)
    }
    if (debouncedInputValue !== '') {
      return void fetchData()
    }

    setOptions([])
  }, [debouncedInputValue, trigger])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <>
      <Autocomplete
        options={options}
        data-testid="occupation-groups-field"
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            label={occupationGroupsAutocompleteNoOptionsText}
            required
            error={fieldState.error?.message ? true : false}
            helperText={fieldState.error?.message}
            onChange={handleInputChange}
          />
        )}
        inputValue={inputValue}
        onChange={(event: unknown, newValue: OccupationGroup | null) => {
          // Sometimes newValue is null on this event so we need to have a null check here.
          if (newValue) {
            onChange([
              ...(occupationgroups as OccupationGroup[]),
              {
                id: newValue.id,
                occupationGroupName: newValue.occupationGroupName,
              },
            ])
            setInputValue('')
          }
        }}
        getOptionLabel={(option) => option.occupationGroupName}
        noOptionsText={occupationGroupsAutocompleteNoOptionsText}
        groupBy={(option) => option.parent?.title ?? ''}
        getOptionDisabled={(option) =>
          occupationgroups?.some(
            (e) => e.occupationGroupName === option.occupationGroupName
          ) ?? false
        }
      />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={'13px'}
        flexWrap="wrap"
        useFlexGap
      >
        {occupationgroups?.map((occupationGroup) => {
          return (
            <Chip
              key={occupationGroup.id}
              variant="outlined"
              onDelete={() => {
                onChange(
                  occupationgroups.filter(
                    (val) => val.id !== occupationGroup.id
                  )
                )
              }}
              label={occupationGroup.occupationGroupName}
            />
          )
        })}
      </Stack>
    </>
  )
}

export default forwardRef(OccupationGroupsAutocomplete)

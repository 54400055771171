import { getAppConfig, getIdToken } from '@trr/app-shell-data'

const {
  COMMON: { MEDARBETARE_API_URL },
} = getAppConfig()

const fetchUserProfile = async (id: string) => {
  const response = await fetch(
    `${MEDARBETARE_API_URL}/v1.0/UserProfile/${id}/klient`,
    {
      headers: { Authorization: `Bearer ${getIdToken() ?? ''}` },
    }
  )
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const resp = await response.json()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { data: resp }
  } catch (error) {
    return { error }
  }
}

export default fetchUserProfile

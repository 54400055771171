import { getAppConfig, getIdToken } from '@trr/app-shell-data'

const {
  COMMON: { MEDARBETARE_API_URL },
} = getAppConfig()

const downloadBlobHelper = async (id: string, fileName: string) => {
  const response = await fetch(
    `${MEDARBETARE_API_URL}/v1.0/kompetens/IndustryReportAdmin/download/${id}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getIdToken() ?? ''}`,
      },
    }
  )
  const blob = await response.blob()
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  const objectUrl = window.URL.createObjectURL(blob)
  anchor.href = objectUrl
  anchor.download = fileName
  anchor.target = '_blank'
  anchor.click()
}

export default downloadBlobHelper

import { OccupationGroup } from 'types/OccupationGroup'
import { County, Region } from 'types/IndustryReport'
import { Data } from 'features/IndustryReport/IndustryReportList/Filters/Filter/Filter'

import api from '../api'

const referenceDataApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOccupationGroupSuggestions: build.query<OccupationGroup[], string>({
      query: (occupationGroup) =>
        `/v1.0/referencedata/occupationgroups/suggestions/${occupationGroup}`,
      providesTags: (result, error, arg) => [
        { type: 'OccupationGroup', id: arg },
      ],
    }),
    getLocationSuggestions: build.query<Region[], string>({
      query: (region) =>
        `/v1.0/referencedata/locations/suggest/${region}?types=County`,
      providesTags: (result, error, arg) => [{ type: 'Region', id: arg }],
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getAllCounties: build.query<Data[], void>({
      query: () => `/v1.0/referencedata/locations/counties`,
      transformResponse: (response: County[]): Data[] => {
        return response.map((el) => ({
          id: el.countyCode.startsWith('0')
            ? el.countyCode.slice(1)
            : el.countyCode,
          label: el.county,
        }))
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'County' as const,
                id,
              })),
              'County',
            ]
          : ['County'],
    }),
  }),
})

export const {
  useLazyGetOccupationGroupSuggestionsQuery,
  useLazyGetLocationSuggestionsQuery,
  useGetAllCountiesQuery,
} = referenceDataApi

import { Status } from 'types/IndustryReport'

const publicationVisibilityMapper = (
  status: Status,
  allString: string,
  administratorsString: string
) => {
  switch (status) {
    case 'Published':
      return allString
    case 'Draft':
      return administratorsString
    case 'Archived':
      return administratorsString

    default:
      return ''
  }
}

export default publicationVisibilityMapper

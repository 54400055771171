import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import {
  DataGrid,
  type GridColDef,
  type GridRenderCellParams,
  GridSearchIcon,
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import {
  useGetIndustryReportsQuery,
  useGetOccupationGroupsQuery,
} from 'services/kompetens'
import { IndustryReport } from 'types'
import { useHistory } from 'react-router-dom'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'
import Header from 'components/Header'
import { formatRelative, parseISO } from 'date-fns'
import { useGetAllCountiesQuery } from 'services/referenceData'
import { sv } from 'date-fns/locale'
import { publicationStatusMapper, publicationVisibilityMapper } from 'utils'
import { Status } from 'types/IndustryReport'
import { date } from '@trr/frontend-datetime'

import Filters from './Filters'
import { FilterType } from './Filters/Filter/Filter'

const IndustryReportList = () => {
  const {
    prognosportalenAdministration: {
      editedAt,
      missingData,
      publicationDate,
      title,
      publisher,
      status,
      draft,
      published,
      archived,
      visibility,
      all,
      administrators,
      regions: regionsLabel,
      occupationGroups: occupationGroupsLabel,
      newPublicationButtonLabel,
      hits,
      noHits,
      labelRowsPerPage,
      noPublicationsFound,
      search,
    },
  } = useEpiContent<Content>()
  const [searchQuery, setSearchQuery] = useState('')
  const { data: occupationGroups } = useGetOccupationGroupsQuery()
  const { data: regions } = useGetAllCountiesQuery()
  interface UpdatedAt {
    updatedAt: string
  }

  const columns: GridColDef[] = [
    {
      field: 'revisions',
      headerName: editedAt,
      sortable: false,
      flex: 1,
      valueGetter: (value: UpdatedAt[]) => {
        if (!value.length) return ''

        const { updatedAt } = value[value.length - 1]
        if (!updatedAt) return ''

        return formatRelative(parseISO(updatedAt), Date.now(), { locale: sv })
      },
    },
    {
      field: 'publicationDate',
      headerName: publicationDate,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value === '')
          return (
            <Box sx={{ color: (theme) => theme.palette.text.secondary }}>
              <i>{missingData}</i>
            </Box>
          )
      },
      valueGetter: (value: string): string => {
        if (!value) return ''

        return date.format(value as unknown as string, {
          style: 'numeric',
        })
      },
    },
    {
      field: 'title',
      headerName: title,
      sortable: false,
      flex: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (!params.value)
          return (
            <Box sx={{ color: (theme) => theme.palette.text.secondary }}>
              <i>{missingData}</i>
            </Box>
          )
      },
    },
    {
      field: 'publisher',
      headerName: publisher,
      sortable: false,
      flex: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (!params.value)
          return (
            <Box sx={{ color: (theme) => theme.palette.text.secondary }}>
              <i>{missingData}</i>
            </Box>
          )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: (value: Status): string =>
        publicationStatusMapper(value, published, draft, archived),
      sortable: false,
      flex: 1,
    },
    {
      field: 'visibility',
      headerName: visibility,
      valueGetter: (_undefined, row: IndustryReport): string =>
        publicationVisibilityMapper(row.status, all, administrators),
      sortable: false,
      flex: 1,
    },
  ]

  const [filters, setFilters] = useState<FilterType[]>([
    {
      id: '1',
      name: 'statuses',
      label: status,
      filters: [],
      data: [
        { id: 'Draft', label: draft },
        { id: 'Published', label: published },
        { id: 'Archived', label: archived },
      ],
    },
    {
      id: '2',
      name: 'regions',
      label: regionsLabel,
      filters: [],
      data: [],
    },
    {
      id: '3',
      name: 'occupationGroups',
      label: occupationGroupsLabel,
      filters: [],
      data: [],
    },
  ])
  const history = useHistory()

  const { data, isLoading } = useGetIndustryReportsQuery({
    searchQuery,
    regions: filters.find((el) => el.name === 'regions')?.filters,
    statuses: filters.find((el) => el.name === 'statuses')?.filters,
    occupationGroups: filters.find((el) => el.name === 'occupationGroups')
      ?.filters,
  })

  const {
    prognosportalenAdministration: { heading, preamble },
  } = useEpiContent<Content>()

  const rows =
    data?.map((el: IndustryReport) => ({
      id: el.id,
      publicationDate: el.publicationDate,
      title: el.title,
      publisher: el.publisher,
      type: el.type,
      status: el.status,
      revisions: el.revisions,
    })) ?? []

  const searchChangeHandler = (event: React.ChangeEvent) => {
    setSearchQuery((event.target as HTMLInputElement).value)
  }

  const debouncedSearchHandler = useCallback(
    debounce(searchChangeHandler, 300),
    []
  )

  const onChangeFilter = (filterId: string, value: string) => {
    const newData = filters.map((el) => {
      if (el.id === filterId) {
        if (!el.filters.includes(value)) {
          el.filters = [...el.filters, value]
        } else {
          el.filters = el.filters.filter((el) => el !== value)
        }
      }
      return el
    })

    setFilters(newData)
  }

  const onResetFilters = (filterId: string) => {
    const newData = filters.map((el) => {
      if (el.id === filterId) {
        el.filters = []
      }
      return el
    })

    setFilters(newData)
  }

  useEffect(() => {
    if (regions && regions.length > 0) {
      const fetchedData = filters.map((el) => {
        if (el.name === 'regions') {
          el.data = regions
        }
        if (el.name === 'occupationGroups') {
          el.data = occupationGroups
        }

        return el
      })

      setFilters(fetchedData)
    }
  }, [regions, occupationGroups])

  if (isLoading) return <CircularProgress />

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Header heading={heading} preamble={preamble} />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 4 }}
        sx={{
          justifyContent: { sm: 'space-between' },
          alignItems: { sm: 'center' },
        }}
      >
        <TextField
          label={search}
          aria-label={search}
          onChange={debouncedSearchHandler}
          data-testid="search-input"
          sx={{ width: { md: '580px' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <GridSearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          href="/medarbetare/prognosportalen-administration/ny-publikation"
          startIcon={<AddIcon />}
          data-testid="new-publication-button"
        >
          {newPublicationButtonLabel}
        </Button>
      </Stack>

      <Filters
        filters={filters}
        handleSetFilters={onChangeFilter}
        handleResetFilters={onResetFilters}
      />

      <Typography
        variant="subtitle1"
        sx={{ paddingBottom: '2em' }}
        data-testid="hits"
      >
        {rows.length > 0 ? `${rows.length.toString()} ${hits}` : noHits}
      </Typography>
      {rows.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          data-testid="publication-list"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20, 30]}
          onRowClick={(e) => {
            history.push(
              `/medarbetare/prognosportalen-administration/redigera-publikation/${e.id.toString()}`
            )
          }}
          slotProps={{
            pagination: { labelRowsPerPage: `${labelRowsPerPage}:` },
            row: {
              'data-testid': 'grid-row',
            },
          }}
          sx={{
            // disable cell selection style
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            // pointer cursor on ALL rows
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnMenu
        />
      ) : (
        <Box>
          <Card sx={{ background: (theme) => theme.palette.surface?.blue }}>
            <CardContent>{noPublicationsFound}</CardContent>
          </Card>
        </Box>
      )}
    </Box>
  )
}
export default IndustryReportList

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'
import queryString from 'query-string'

const {
  COMMON: { MEDARBETARE_API_URL },
} = getAppConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: MEDARBETARE_API_URL,
    paramsSerializer: (params: Record<string, string | number | boolean>) =>
      queryString.stringify(params),
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Accept-Language', 'sv')

      return headers
    },
  })

const api = createApi({
  reducerPath: 'api',
  baseQuery: trrFetchBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ['IndustryReport', 'OccupationGroup', 'Region', 'County'],
})

export default api

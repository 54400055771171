/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react'

interface IAppshellRouterProps extends React.PropsWithChildren {
  currentKey: string
}

export const AppShellRouter: React.FunctionComponent<IAppshellRouterProps> = ({
  currentKey,
  children,
}) => {
  let component

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.urlKey === currentKey) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component
}

import { Box, Typography } from '@mui/material'

const Header = ({
  heading,
  preamble,
}: {
  heading: string
  preamble?: string
}) => {
  return (
    <Box sx={{ paddingBottom: 5 }}>
      <Typography variant="h1" data-testid="heading">
        {heading}
      </Typography>
      {preamble && (
        <Typography variant="preamble" data-testid="preamble">
          {preamble}
        </Typography>
      )}
    </Box>
  )
}

export default Header

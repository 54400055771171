import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import Upload from '@mui/icons-material/Upload'
import { useFilePicker } from 'use-file-picker'
import { useAddIndustryReportMutation } from 'services/kompetens'
import { useHistory } from 'react-router-dom'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'
import Header from 'components/Header'

const IndustryReportCreate = () => {
  const history = useHistory()
  const {
    nyPublikation: {
      heading,
      preamble,
      reportHeader,
      reportParagraph,
      allowedFileFormatsText,
      pickFileLabel,
      abortButtonLabel,
      createPublicationButtonLabel,
    },
  } = useEpiContent<Content>()
  const [addReport] = useAddIndustryReportMutation()
  const { openFilePicker, plainFiles } = useFilePicker({
    accept: ['.pdf'],
    multiple: false,
    initializeWithCustomParameters: (inputElement) => {
      inputElement.setAttribute('data-testid', 'file-input')
    },
  })

  const handleUploadFile = async () => {
    if (plainFiles.length > 0) {
      const response = await addReport(plainFiles[0]).unwrap()
      history.push(`redigera-publikation/${response.id ?? ''}`)
    }
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Header heading={heading} preamble={preamble} />
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h5">{reportHeader}</Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {reportParagraph}
          <br />
          {allowedFileFormatsText}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<Upload />}
          onClick={openFilePicker}
          sx={{ marginBottom: 2 }}
          data-testid="select-file-button"
        >
          {pickFileLabel}
        </Button>
        <Box display="flex" flexDirection="column" gap={1}>
          {plainFiles.map((file, index) => {
            return (
              <Box key={`${file.name}_${index.toString()}`}>
                <Chip label={file.name} />
              </Box>
            )
          })}
        </Box>
      </Box>
      <Stack direction={'row'} spacing={2}>
        <Button
          variant="outlined"
          href="/medarbetare/prognosportalen-administration/"
          data-testid="cancel-button"
        >
          {abortButtonLabel}
        </Button>
        <Button
          onClick={() => void handleUploadFile()}
          data-testid="upload-file-button"
        >
          {createPublicationButtonLabel}
        </Button>
      </Stack>
    </Box>
  )
}

export default IndustryReportCreate
